import React, { FC, useState, useContext } from "react";
import { StateContext as DashboardContext } from "../../../../context/dashboard/Provider";
import CheckboxTables from "../checkboxTables";
import GeneralPerSeason from "../../../data/GeneralPerSeason";
import Precipitation from "../../../data/Precipitation";
import DegreeDay from "../../../data/DegreeDay";

const Detail: FC = () => {
  const [viewSeasonState, setViewSeasonState] = useState<Boolean>(false);
  const [viewDegreeState, setViewDegreeState] = useState<Boolean>(false);
  const [viewPrepState, setViewPrepState] = useState<Boolean>(false);
  const toggleSeason = () => setViewSeasonState(!viewSeasonState);
  const toggleDegree = () => setViewDegreeState(!viewDegreeState);
  const togglePrep = () => setViewPrepState(!viewPrepState);
  const dashboardState = useContext(DashboardContext);

  return (
    <>
      <h4>Gráficos históricos de finca</h4>

      <CheckboxTables
        changeSeason={toggleSeason}
        changeDegree={toggleDegree}
        changePrep={togglePrep}
      />

      {viewSeasonState ? (
        <section className="row my-4">
          <div className="col-xl-10">
            <GeneralPerSeason />
          </div>
        </section>
      ) : null}
      {viewPrepState ? (
        <section className="row my-4">
          <div className="col-xl-10">
            <Precipitation />
          </div>
        </section>
      ) : null}
      {viewDegreeState ? (
        <section className="row my-4">
          <div className="col-xl-10">
            <DegreeDay />
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Detail;
