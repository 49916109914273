import React, { FC } from "react";
import * as classes from "./tables.module.css";
import {
  redScale,
  blueScale,
  orangeScale,
  greenScale,
  yellowScale,
  grayScale,
} from "../../utils";

interface tableHeaderProps {
  //Change this name
  daysToMatchCurrentTemperature?: boolean;
  averageAccumulated?: boolean;
  mainColumnHeader?: string;
}

export const TableHeader: FC<tableHeaderProps> = ({
  daysToMatchCurrentTemperature = false,
  averageAccumulated = false,
  mainColumnHeader = "Temporada",
}) => {
  return (
    <thead style={{ minHeight: "90px" }}>
      <th className={`${classes.cell} ${classes.dateCell}`}>
        {mainColumnHeader}
      </th>
      <td style={{ padding: "0" }} className={classes.cell}>
        <div className="d-block h-100">
          <div className="h-75">
            <div className="row">
              <td colSpan={100}>% Horas segun temperatura</td>
            </div>
          </div>
          <div className="h-25">
            <div className="row mx-0 h-100">
              <div
                className="col-4"
                style={{ backgroundColor: blueScale([])(1).css() }}
              >
                &lt;10&deg;
              </div>
              <div
                className="col-4"
                style={{ backgroundColor: orangeScale([])(1).css() }}
              >
                &gt;30&deg;
              </div>
              <div
                className="col-4"
                style={{
                  backgroundColor: redScale([])(1).css(),
                  color: "white",
                }}
              >
                &gt;33&deg;
              </div>
            </div>
          </div>
        </div>
      </td>

      <td
        className={classes.cell}
        style={{ backgroundColor: greenScale([])(1).css() }}
      >
        Grados días acumulados
      </td>

      {averageAccumulated && (
        <td className={classes.cell}>Grados dias promedio</td>
      )}

      {daysToMatchCurrentTemperature && (
        <td className={classes.cell}>Días para igualar temporada actual</td>
      )}

      <td
        className={classes.cell}
        style={{ backgroundColor: yellowScale([])(1).css() }}
      >
        Amplitud térmica
      </td>

      <td
        className={classes.cell}
        style={{ backgroundColor: grayScale([])(1).css() }}
      >
        Precipitaciones acumuladas [mm]
      </td>
      <td className={classes.cell}>Porcentaje de datos</td>
    </thead>
  );
};

export const TdGroup: FC = ({ children }) => {
  return (
    <td className={classes.tdGroup}>
      <table style={{ position: "relative" }}>
        <tr
          style={{
            display: "grid",
            gridTemplateRows: "100%",
            gridTemplateColumns: "repeat(3, 1fr)",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          {children}
        </tr>
      </table>
    </td>
  );
};
