import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./components/pages/Home";
import Login from "./components/pages/Login";
import AuthProvider from "./context/auth/AuthProvider";
import ErrorBoundary from "./components/hoc/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
