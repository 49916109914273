import React, { FC, useState, useEffect, useContext } from "react";
import { monthlyPrecip } from "../../../api";
import { UserStateContext } from "../../../context/auth/AuthProvider";
import { StateContext } from "../../../context/dashboard/Provider";
import { Precipitations } from "../../../types";
import * as classes from "../tables.module.css";
import { blueScale, campString } from "../../../utils";

interface PrecipitationProps {}

const colorScale = blueScale([0, 300]);

const ResultTd = ({ value }: any) => (
  <td
    className={classes.cell}
    style={{ backgroundColor: (colorScale(value) as any).css() as any }}
  >
    {value ?? "-"}
  </td>
);

const Precipitation: FC<PrecipitationProps> = () => {
  const [data, setData] = useState<Precipitations[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userToken } = useContext(UserStateContext);
  const { from, to, sector } = useContext(StateContext);

  useEffect(() => {
    if (!userToken || !sector) return;
    setIsLoading(true);
    monthlyPrecip(from, to, sector, userToken).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [from, to, userToken, sector]);

  const rows = data?.map((x) => (
    <tr key={x.initial_date}>
      <th className={classes.cell}>
        {campString(x.initial_date, x.final_date)}
      </th>
      <ResultTd value={x.months[10]} />
      <ResultTd value={x.months[11]} />
      <ResultTd value={x.months[12]} />
      <ResultTd value={x.months[1]} />
      <ResultTd value={x.months[2]} />
      <ResultTd value={x.months[3]} />
      <td className={classes.cell}>
        {x.data_percentage ? `${x.data_percentage}%` : "-"}
      </td>
    </tr>
  ));

  return (
    <section>
      <h5>Precipitaciones mensuales - {data ? data[0].station.title : null}</h5>

      <table className={`${classes.table} ${classes.widthSeven}`}>
        <thead>
          <th className={`${classes.cell} ${classes.dateCell}`}>Temporada</th>
          <td className={classes.cell}>Octubre</td>
          <td className={classes.cell}>Noviembre</td>
          <td className={classes.cell}>Diciembre</td>
          <td className={classes.cell}>Enero</td>
          <td className={classes.cell}>Febrero</td>
          <td className={classes.cell}>Marzo</td>
          <td className={classes.cell}>Porcentaje de datos</td>
        </thead>
        <tbody>{data && !isLoading && rows}</tbody>
      </table>
      {isLoading && (
        <div className="d-flex py-3 justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {data && data.length === 0 && (
        <div className="d-flex py-3 justify-content-center">
          <div role="status">
            <p>Sin datos</p>
          </div>
        </div>
      )}
    </section>
  );
};
export default Precipitation;
