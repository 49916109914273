import React, { useState } from "react";
import Sidebar from "./sidebar";
import "bootstrap-icons/font/bootstrap-icons.css";

const Layout = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const toggle = () => {
    setIsOpen((s) => !s);
  };

  const sbClass = `${isOpen ? "" : "d-none d-sm-block"}`;

  return (
    <>
      <div className="container-fluid mx-auto px-0 row flex-nowrap">
        <section
          className={`sidebar bg-light col-auto vh-100 sticky-top col-1 ${sbClass}`}
        >
          <Sidebar />
        </section>
        <main className="col p-3">{children}</main>
      </div>
    </>
  );
};

export default Layout;
