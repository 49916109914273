import React, { FC } from "react";

interface calendarInputProps {
  onChange: Function;
  name: string;
  value?: string | null;
  min?: string;
  max?: string;
  [index: string]: any;
}

const CalendarInput: FC<calendarInputProps> = ({ className, ...props }) => {
  return (
    <>
      <label htmlFor={props.name} className="form-label">
        {props.labelTitle}
      </label>
      <input
        {...(props as any)}
        // defaultValue={defaultValue}
        className={`form-control ${className}`}
        type="date"
        id="start"
      />
    </>
  );
};

export default CalendarInput;
