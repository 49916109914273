import { Reducer } from "react";
import { campMaxMin, getCampList, getLastOrCurrentCamp } from "../../utils";
import { Action } from "./actionTypes";

export const defaultState = {
  year: null,
  from: null,
  to: null,
};

export const getInitialState = (): State => {
  const lastCamp = getLastOrCurrentCamp();
  const {min, max} = campMaxMin(lastCamp);

  return {
    sector: null,
    year: lastCamp,
    years: getCampList(),
    from: min,
    to: max,
    minDate: min,
    maxDate: max,
  };
};

// Sector = Station
export type State = {
  sector: string | null;
  to: string;
  from: string;
  year: string;
  years: Array<string>;
  maxDate: string;
  minDate: string;
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "SET_SECTOR":
      return {
        ...state,
        sector: action.sector!,
      };
    case "SET_MIN_DATE":
      return {
        ...state,
        from: action.date ?? "",
        minDate: action.date ?? "",
      };
    case "SET_MAX_DATE":
      return {
        ...state,
        to: action.date ?? "",
        maxDate: action.date ?? "",
      };
    case "SET_MIN_MAX_DATE":
      return {
        ...state,
        to: action.maxDate ?? "",
        maxDate: action.maxDate ?? "",
        from: action.minDate ?? "",
        minDate: action.minDate ?? "",
      };
    case "SET_TO_CONTROL":
      return {
        ...state,
        to: action.to!,
      };
    case "SET_FROM_CONTROL":
      return {
        ...state,
        from: action.from!,
      };
    case "SET_YEAR_CONTROL":
      return {
        ...state,
        year: action.year!,
      };
    default:
      return state;
  }
};

export default reducer;
