import React, { createContext, useReducer, Dispatch, FC } from "react";
import reducer, { State, getInitialState } from "./reducer";
import { Action } from "./actionTypes";

const Provider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={asyncDispatchWrap(dispatch)}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

type AsyncDispatch = (
  action: Action | ((...args: any) => Promise<any>)
) => void;

function asyncDispatchWrap(dispatch: Dispatch<Action>) {
  const asyncDispatch: AsyncDispatch = (action) => {
    if (action instanceof Function) {
      action(dispatch);
      return;
    }
    dispatch(action);
  };

  return asyncDispatch;
}

// Este no es el estado default!
// createContext necesita un valor default pero los providers siempre van a tener una prop 'value'
export const StateContext = createContext<State>({
  sector: null,
  year: "",
  from: "",
  to: "",
  maxDate: "",
  minDate: "",
  years: [],
});
export const DispatchContext = createContext<
  (action: Action | ((...args: any) => Promise<any>)) => void
>(asyncDispatchWrap((dispatch) => {}));

export default Provider;
