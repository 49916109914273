import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../context/auth/actions";
import { UserDispatchContext } from "../../context/auth/AuthProvider";

const NavLink = ({ children, ...props }: any) => (
  <Link className="nav-link" {...props}>
    {children}
  </Link>
);

const Sidebar = () => {
  const dispatch = useContext(UserDispatchContext);

  return (
    <aside className="py-5 d-flex flex-column h-100">
      <ul className="nav nav-pills text-center flex-column mb-auto">
        <li>
          <NavLink to="/">Inicio</NavLink>
        </li>
      </ul>
      <button
        className="nav-link btn btn-link"
        onClick={() => dispatch(logout())}
      >
        Logout
      </button>
    </aside>
  );
};

export default Sidebar;
