import { Dispatch } from "react";
import { Action } from "./actionTypes";
import { login as apiLoginRequest } from "../../api";
import keys from "../storageKeys";

// Accion asincrona: Loguea al usuario
export const login = (username: string, password: string) => async (
  dispatch: Dispatch<Action>
) => {
  dispatch(loginRequest());

  const errors = {
    400: "Credenciales inválidas",
  };

  try {
    const res = await apiLoginRequest(username, password);
    if (!res.ok) {
      const msg = (errors as any)[res.status];
      dispatch(loginFailure(msg ?? "Request inválida"));
      const text = await res.text();
      console.log("Error en el login", text);
      return;
    }

    const body = await res.json();

    const userToken = body.access_token;
    localStorage.setItem(keys.username, username);
    localStorage.setItem(keys.userToken, userToken);

    dispatch(loginSuccess(username, userToken));
  } catch (e) {
    console.error(e);
    dispatch(loginFailure("Error de conexión"));
  }
};

// Accion: estado de carga durante login pendiente
export const loginRequest = (): Action => ({
  type: "LOGIN_REQUEST",
});

// Accion: Estado de login exitoso
export const loginSuccess = (username: string, userToken: string): Action => ({
  type: "LOGIN_SUCCESS",
  username,
  userToken,
});

// Accion: Estado de login fallido
export const loginFailure = (code: string): Action => ({
  type: "LOGIN_FAILURE",
  error: code,
});

// Accion: Quitar estado de error
export const failureDismiss = (): Action => ({
  type: "LOGIN_FAILURE_DISMISS",
});

// Accion: logout
export const logoutSync = (): Action => ({
  type: "LOGOUT",
});

export const logout = () => async (dispatch: Dispatch<Action>) => {
  localStorage.removeItem(keys.userToken);

  dispatch(logoutSync());
};
