import React, {
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  FC,
  useMemo,
} from "react";
import Select from "../../forms/select";
import CalendarInput from "../../forms/calendarInput";
import {
  DispatchContext,
  StateContext,
} from "../../../../context/dashboard/Provider";
import * as actions from "../../../../context/dashboard/actions";
import { sectors } from "../../../../api";
import { UserStateContext } from "../../../../context/auth/AuthProvider";
import { Station } from "../../../../types";
import {
  campMaxMin,
  campString,
  getCampList,
  mustCheckDateOrder,
} from "../../../../utils";
import Modal from "../../../portals/modal";

const Cockpit: FC = () => {
  const [error, setError] = useState<string | null>(null);

  const dashboardState = useContext(StateContext);
  const dashboardDispatch = useContext(DispatchContext);
  const userState = useContext(UserStateContext);
  const [sectorList, setSectorList] = useState<Station[]>([]);

  // Opciones para selector de campania
  const selectedCampaignYear = dashboardState.year;
  const campaignList = dashboardState.years.map((v, _) => ({
    title: v,
    value: v,
  }));

  // Inicializacion del selector de fincas
  useEffect(() => {
    const token = userState.userToken;
    if (!token) return;
    sectors(token).then((list) => {
      setSectorList(list);
      if (list.length > 0) {
        dashboardDispatch(actions.setSector(list[0].station_code));
      }
    });
  }, []);

  const sectorChoices = useMemo(
    () =>
      sectorList.map(({ title, station_code }) => ({
        title,
        value: station_code,
      })),
    [sectorList]
  );

  const handleCampChange = (e: ChangeEvent<HTMLInputElement>) => {
    const camp = campMaxMin(e.target.value);
    dashboardDispatch(actions.setMinMaxDate(camp.min, camp.max));
    dashboardDispatch(actions.setYearControl(e.target.value));
  };

  const handleFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      mustCheckDateOrder(e.target.value, dashboardState.to);
    } catch (e) {
      setError(e as any);
      return;
    }
    return dashboardDispatch(actions.setFromControl(e.target.value));
  };

  const handleToChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      mustCheckDateOrder(dashboardState.from, e.target.value);
    } catch (e) {
      setError(e as any);
      return;
    }
    return dashboardDispatch(actions.setToControl(e.target.value));
  };

  return (
    <>
      {error && (
        <Modal
          key="Error"
          title={"Error"}
          staticBackdrop
          accept={() => setError(null)}
        >
          <p style={{ color: "red" }}>{error.toString()}</p>
        </Modal>
      )}
      <section className="row mb-3">
        {/* Finca */}
        <div className="col-12 col-lg-4 mb-2 col-xl-3 mb-xl-0">
          <Select
            labelTitle="Finca"
            list={sectorChoices}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              dashboardDispatch(actions.setSector(e.target.value))
            }
            name="finca"
            placeholder="Fincas"
          />
        </div>

        {/* Campania */}
        <div className="col-6 col-lg-4 mb-2 col-xl-auto mb-xl-0">
          <Select
            list={campaignList}
            onChange={handleCampChange}
            value={selectedCampaignYear}
            name="campania"
            labelTitle="Campaña"
            placeholder="Camapaña"
          />
        </div>

        {/* Desde */}
        <div className="col-6 col-lg-4 mb-2 col-xl-auto mb-xl-0">
          <CalendarInput
            onChange={handleFromChange}
            value={dashboardState.from}
            min={dashboardState.minDate}
            max={dashboardState.maxDate}
            name="desde"
            labelTitle="Fecha desde"
          />
        </div>

        {/* Hasta */}
        <div className="col-6 col-lg-4 mb-2 col-xl-auto mb-xl-0">
          <CalendarInput
            onChange={handleToChange}
            value={dashboardState.to}
            min={dashboardState.minDate}
            max={dashboardState.maxDate}
            name="hasta"
            labelTitle="Fecha hasta"
          />
        </div>
      </section>
      <h5>
        Temporada {parseInt(dashboardState.year) - 1} - {dashboardState.year} -
        Vendimia {parseInt(dashboardState.year)}
      </h5>
    </>
  );
};

export default Cockpit;
