import React, { FC, useEffect, useContext, useState } from "react";
import { seasonsSummariesTable } from "../../../api/tables";
import { Summary } from "../../../types/summary";
import { TableHeader as Header, TdGroup } from "../shared";
import * as classes from "../tables.module.css";
import { StateContext as DashboardContext } from "../../../context/dashboard/Provider";
import { UserStateContext } from "../../../context/auth/AuthProvider";
import { campString, grayScale, greenScale, yellowScale } from "../../../utils";
import { redScale, orangeScale, blueScale } from "../../../utils";

const TemperaturePerSeason: FC = () => {
  const [data, setData] = useState<Summary[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { sector, from, to } = useContext(DashboardContext);
  const { userToken } = useContext(UserStateContext);

  useEffect(() => {
    if (!sector || !userToken) return;
    setIsLoading(true);
    seasonsSummariesTable(from, to, sector, userToken).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [from, to, sector, userToken]);

  const rows = data?.map((x) => (
    <tr key={x.initial_date}>
      <th className={`${classes.cell} ${classes.nowrap}`}>
        {campString(x.initial_date, x.final_date)}
      </th>
      <TdGroup>
        <td
          style={{ backgroundColor: blueScale([0, 50])(x.lt10).css() as any }}
        >
          {x.lt10 ?? "-"}%
        </td>
        <td
          style={{ backgroundColor: orangeScale([0, 50])(x.gt33).css() as any }}
        >
          {x.gt30 ?? "-"}%
        </td>
        <td style={{ backgroundColor: redScale([0, 50])(x.gt33).css() as any }}>
          {x.gt33 ?? "-"}%
        </td>
      </TdGroup>

      <td
        className={classes.cell}
        style={{
          backgroundColor: greenScale([0, 3000])(x.grados_acumulados).css(),
        }}
      >
        {x.grados_acumulados ?? "-"}
      </td>
      <td className={classes.cell}>{x.dias_igualar_temporada ?? "-"}</td>
      <td
        className={classes.cell}
        style={{
          backgroundColor: yellowScale([0, 50])(x.amplitud_termica).css(),
        }}
      >
        {x.amplitud_termica ?? "-"}
      </td>
      <td
        className={classes.cell}
        style={{
          backgroundColor: grayScale([0, 300])(x.precip_acumulada).css(),
        }}
      >
        {x.precip_acumulada ?? "-"}
      </td>
      <td className={classes.cell}>
        {x.data_percentage ? `${x.data_percentage}%` : "-"}
      </td>
    </tr>
  ));

  return (
    <>
      <h5>General - {data ? data[0].station.title : null}</h5>
      <table className={`${classes.table} ${classes.widthSix}`}>
        <Header daysToMatchCurrentTemperature={true} />
        <tbody>{data && !isLoading && rows}</tbody>
      </table>
      {isLoading && (
        <div className="d-flex py-3 justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {data && data.length === 0 && (
        <div className="d-flex py-3 justify-content-center">
          <div role="status">
            <p>Sin datos</p>
          </div>
        </div>
      )}
    </>
  );
};
export default TemperaturePerSeason;
