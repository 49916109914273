import { scale } from "chroma-js";

export function checkDateOrder(first: string, second: string) {
  const firstTs = Date.parse(first);
  const secondTs = Date.parse(second);
  return firstTs <= secondTs;
}

export function mustCheckDateOrder(first: string, second: string) {
  if (!checkDateOrder(first, second)) {
    throw new Error(
      "La fecha inicial no puede ser posterior a la fecha final."
    );
  }
}

export const campMaxMin = (year: string) => {
  let maxDate;
  let minDate;

  let now = new Date();

  if (isCampInProgress(year, now)) {
    // La temporada empezó y es la de este año
    maxDate = now;
    minDate = new Date(parseInt(year) - 1, 9, 1);
  } else {
    // Las fechas extremas de la temporada del año seleccionado
    maxDate = new Date(parseInt(year), 3, 30);
    minDate = new Date(parseInt(year) - 1, 9, 1);
  }

  // Conseguimos el string yyyy-mm-dd
  const max = maxDate.toISOString().slice(0, 10);
  const min = minDate.toISOString().slice(0, 10);

  return { min, max };
};

export const isCampInProgress = (camp: string, date: Date): boolean => {
  let campStartDate = new Date(parseInt(camp) - 1, 9, 1);
  let campEndDate = new Date(parseInt(camp), 3, 30);

  return campStartDate <= date && date <= campEndDate;
};

export const getLastOrCurrentCamp = (): string => {
  let now = new Date();

  if (now.getMonth() >= 9 && now.getDay() >= 1) {
    return (now.getFullYear() + 1).toString();
  }

  return now.getFullYear().toString();
};

// Computa las campanias para seleccionar
// a partir de la ultima campania y la cantidad de anios para atras
const campList = (lastCampaignYear: string, length: number): string[] =>
  Array.from({ length }).map((_, i) =>
    (parseInt(lastCampaignYear) - length + 1 + i).toString()
  );

export const getCampList = (): string[] => {
  const year = getLastOrCurrentCamp();
  // La campania mas vieja sera 2011.
  const length = parseInt(year) - 2010;
  return campList(year, length);
};

function campYear(ISOFrom: string): number {
  // El año de la temporada 2021 - 2022 es 2022
  // "El año empieza el 1 de Octubre"
  // Offset desde el 1 de Octubre hasta el 1 de Enero
  const offset = 7948800000;
  const offsetDate = new Date(Date.parse(ISOFrom) + offset);

  return offsetDate.getFullYear();
}

// Computa un string para que el usuario identifique la temporada que esta viendo
export function campString(
  ISOFrom: string,
  ISOTo: string,
  withVintage?: boolean
) {
  const yr = campYear(ISOFrom);

  // Con vendimia
  if (withVintage) {
    return `${yr - 1} - ${yr} - Vendimia ${yr} `;
  }
  // Sin vendimia
  return `${yr - 1} - ${yr}`;
}

// Escalas de colores
export const orangeScale = (domain: number[]) =>
  scale(["white", "orange"]).domain(domain);
export const redScale = (domain: number[]) =>
  scale(["white", "rgb(192, 0, 0)"]).domain(domain);
export const blueScale = (domain: number[]) =>
  scale(["white", "rgb(58, 145, 207)"]).domain(domain);
export const greenScale = (domain: number[]) =>
  scale(["white", "rgb(169, 208, 142)"]).domain(domain);
export const yellowScale = (domain: number[]) =>
  scale(["white", "rgb(255, 255, 0)"]).domain(domain);
export const grayScale = (domain: number[]) =>
  scale(["white", "rgb(169, 169, 169)"]).domain(domain);
