import React, { FC } from "react";

interface checkProps {
  changeSeason: any;
  changeDegree: any;
  changePrep: any;
}

const CheckboxTables: FC<checkProps> = ({
  changeSeason,
  changeDegree,
  changePrep,
}) => {
  return (
    <section className="row p-lg-4 p-md-3 p-2">
      <div className="col-8" role="group">
        <input
          type="checkbox"
          className="form-check-input me-2"
          onClick={changeSeason}
          id="btncheckSeason"
          autoComplete="off"
        />
        <label className="form-check-label me-3" htmlFor="btncheckSeason">
          General
        </label>

        <input
          type="checkbox"
          className="form-check-input me-2"
          onClick={changePrep}
          id="btncheckPre"
          autoComplete="off"
        />
        <label className="form-check-label me-3" htmlFor="btncheckPre">
          Precipitaciones
        </label>

        <input
          type="checkbox"
          className="form-check-input me-2"
          onClick={changeDegree}
          id="btncheckDegree"
          autoComplete="off"
        />
        <label className="form-check-label me-3" htmlFor="btncheckDegree">
          Grados día
        </label>
      </div>
    </section>
  );
};

export default CheckboxTables;
