import React, { useContext, FC } from "react";
import GeneralPerSector from "../data/GeneralPerSector";
import { UserStateContext } from "../../context/auth/AuthProvider";
import DashboardProvider from "../../context/dashboard/Provider";
import { Redirect } from "react-router-dom";
import Layout from "../layout";
import Cockpit from "../UI/dashboard/cockpit";
import Detail from "../UI/dashboard/detail";

const Home: FC = () => {
  const userState = useContext(UserStateContext);

  if (!userState.loggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Layout>
        <DashboardProvider>
          <h4>Tabla comparativa entre fincas</h4>
          <div className="p-2">
            <Cockpit />
            <section className="row mb-5">
              <div className="col-xl-10">
                <GeneralPerSector />
              </div>
            </section>

            <Detail />
          </div>
        </DashboardProvider>
      </Layout>
    </>
  );
};

export default Home;
