import React, {
  ChangeEvent,
  useContext,
  useMemo,
  FormEventHandler,
  useState,
} from "react";
import {
  UserDispatchContext,
  UserStateContext,
} from "../../context/auth/AuthProvider";
import { Redirect } from "react-router-dom";
import * as classes from "./Login.module.css";
import { failureDismiss, login } from "../../context/auth/actions";
import Modal from "../portals/modal";
import bg from "../../images/background.jpg";
import omx from "../../images/omx-logo.png";
import vis from "../../images/visibility.png";

const Login = () => {
  const [formState, setFormState] = useState<any>({});
  const [passVisible, setPassVisible] = useState(false);
  const dispatch = useContext(UserDispatchContext);
  const userState = useContext(UserStateContext);

  // Medio chota la validacion quizas despues usemos una alternativa mejor
  const isValid = useMemo<Boolean>(() => {
    let valid = true;
    valid = valid && Object.keys(formState).length >= 2;
    for (let key in formState) {
      valid = valid && formState[key] && formState != "";
    }

    return valid;
  }, [formState]);

  const handleFormChange = (e: ChangeEvent) => {
    const el = e.target as HTMLInputElement;
    const name = el.getAttribute("name") as string;
    const value = el.value;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleFormSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    dispatch(login(formState.username, formState.password));
  };

  if (userState.loggedIn) {
    return <Redirect to="/" />;
  }

  // Modal
  let modal = null;
  if (userState.loggingIn) {
    modal = (
      <Modal key="Loading" buttons={false}>
        <p>Espere un momento</p>
      </Modal>
    );
  } else if (userState.error) {
    modal = (
      <Modal
        key="Error"
        title={"Error"}
        staticBackdrop
        accept={() => dispatch(failureDismiss())}
      >
        <p>{userState.error}</p>
      </Modal>
    );
  }

  return (
    <main
      style={{
        background: `url(${bg}) no-repeat`,
        backgroundSize: "cover",
        backgroundPosition: "30%",
      }}
      className="d-flex justify-content-center align-items-center min-vh-100"
    >
      {modal}
      <div className="w-50">
        <form
          onSubmit={handleFormSubmit}
          className={`text-center d-flex flex-column gap-2 mx-auto  ${classes.loginForm}`}
        >
          <h3 className="text-light">Inicio de sesión</h3>
          <input
            onChange={handleFormChange}
            id="username"
            className="form-control"
            type="text"
            name="username"
            placeholder="Usuario"
          />
          <div className={classes.passWrapper}>
            <input
              onChange={handleFormChange}
              id="password"
              className="form-control"
              type={passVisible ? "text" : "password"}
              name="password"
              placeholder="Contraseña"
            />
            <img
              src={vis}
              className={`${classes.vis} ${passVisible ? classes.active : ""}`}
              onClick={() => setPassVisible(!passVisible)}
              alt="Ver contraseña"
            />
          </div>
          <button
            className={`btn btn-primary ${!isValid && "disabled"}`}
            disabled={!isValid}
            type="submit"
          >
            Continuar
          </button>
          <hr className="text-light border" />
          <a
            href="https://new.omixom.com/accounts/login/?next=/"
            target="_blank"
            className="text-start text-light text-decoration-none"
          >
            Crear una cuenta
          </a>
          <a
            href="https://new.omixom.com/accounts/login/?next=/"
            target="_blank"
            className="text-start text-light text-decoration-none"
          >
            Olvide mi contraseña
          </a>
        </form>
      </div>
      <div className={classes.poweredBy}>
        <span className="text-light">Powered by</span>
        <a href="https://www.omixom.com" target="_blank">
          <img src={omx} alt="Logo Omixom" />
        </a>
      </div>
    </main>
  );
};

export default Login;
