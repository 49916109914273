import { Reducer } from "react";
import { Action } from "./actionTypes";
import keys from "../storageKeys";

export const defaultState = {
  loggingIn: false,
  loggedIn: false,
  userToken: null,
  username: null,
};

// Estado inicial de usuario
export const getInitialState = (): State => {
  const userToken = localStorage.getItem(keys.userToken);
  const username = localStorage.getItem(keys.username);
  return { ...defaultState, username, userToken, loggedIn: !!userToken };
};

export type State = {
  loggingIn?: Boolean | null;
  loggedIn?: Boolean | null;
  userToken?: string | null;
  username?: string | null;
  error?: string | null;
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        username: action.username,
      };
    case "LOGIN_SUCCESS":
      return {
        loggedIn: true,
        loggingIn: false,
        username: action.username,
        userToken: action.userToken,
      };
    case "LOGIN_FAILURE_DISMISS":
      return {
        ...state,
        error: undefined,
      };
    case "LOGIN_FAILURE":
      return {
        error: action.error,
      };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
};

export default reducer;
