import React, { createContext, useReducer, Dispatch, FC } from "react";
import reducer, { State, getInitialState } from "./reducer";
import { Action } from "./actionTypes";

const UserProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={asyncDispatchWrap(dispatch)}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

type AsyncDispatch = (
  action: Action | ((...args: any) => Promise<any>)
) => void;

function asyncDispatchWrap(dispatch: Dispatch<Action>) {
  const asyncDispatch: AsyncDispatch = (action) => {
    if (action instanceof Function) {
      action(dispatch);
      return;
    }
    dispatch(action);
  };

  return asyncDispatch;
}

export const UserStateContext = createContext<State>({});
export const UserDispatchContext = createContext<
  (action: Action | ((...args: any) => Promise<any>)) => void
>(asyncDispatchWrap((dispatch) => {}));

export default UserProvider;
