import { Action } from "./actionTypes";
import { mustCheckDateOrder } from "../../utils";

export const setYearControl = (year: string): Action => ({
  type: "SET_YEAR_CONTROL",
  year,
});

export const setFromControl = (from: string): Action => ({
  type: "SET_FROM_CONTROL",
  from,
});

export const setToControl = (to: string): Action => ({
  type: "SET_TO_CONTROL",
  to,
});

export const setSector = (sector: string): Action => ({
  type: "SET_SECTOR",
  sector,
});

export const setMaxDate = (date: string): Action => ({
  type: "SET_MAX_DATE",
  date,
});

export const setMinDate = (date: string): Action => ({
  type: "SET_MIN_DATE",
  date,
});

export const setMinMaxDate = (min: string, max: string): Action => {
  mustCheckDateOrder(min, max);

  return {
    type: "SET_MIN_MAX_DATE",
    minDate: min,
    maxDate: max,
  };
};

export default {};
