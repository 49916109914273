import React, { FC } from "react";

interface selectProps {
  list: any;
  onChange: Function;
  placeholder: string;
  name: string;
  [index: string]: any;
}

// En las props hago un "rest", que me da el resto del objeto que estoy desestructurando
const Select: FC<selectProps> = ({ list, className, ...props }) => {
  return (
    <>
      <label htmlFor={props.name} className="form-label">
        {props.labelTitle}
      </label>
      <select {...(props as any)} className={`form-select ${className}`}>
        {props.placeholder && !list.length ? (
          <option key="placeholder" value="">
            {props.placeholder}
          </option>
        ) : null}

        {list?.map((item: any) => (
          <option value={item.value} key={item.value}>
            {item.title}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
