import { apiURL } from "../config";
import { Summary, Station, DegreeDays } from "../types";
import { Precipitations } from "../types/precipitations";
// Quizas llamarlos table no hacia falta,
// pero es preferible antes de que a alguien se le olvide usar un alias cuando importe estas funciones.

// Wrapper para que si la request sale mal tengamos una rejection
// en lugar de que se resuelva y tengamos que chequar el status
const mustFetchJson = <T>(url: string, config: RequestInit) =>
  new Promise<T>(async (resolve, reject) => {
    const res = await fetch(url, config);
    if (!res.ok) {
      const reason = await res.text();
      reject(reason);
    }

    try {
      const body = await res.json();
      resolve(body);
    } catch (err) {
      reject(err);
    }
  });

export const monthlyDegrees = (
  from: string,
  to: string,
  sector: string,
  token: string
) => {
  const headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Authorization", `Bearer ${token}`);

  const config: RequestInit = {
    method: "GET",
    mode: "cors",
    headers,
  };

  const qParams = new URLSearchParams();
  qParams.append("start_datetime", `${from}T00:00`);
  qParams.append("end_datetime", `${to}T00:00`);

  return mustFetchJson<DegreeDays[]>(
    `${apiURL}station/${sector}/monthly-degrees?${qParams.toString()}`,
    config
  );
};

export const monthlyPrecip = (
  from: string,
  to: string,
  sector: string,
  token: string
) => {
  const headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Authorization", `Bearer ${token}`);

  const config: RequestInit = {
    method: "GET",
    mode: "cors",
    headers,
  };

  const qParams = new URLSearchParams();
  qParams.append("start_datetime", `${from}T00:00`);
  qParams.append("end_datetime", `${to}T00:00`);

  return mustFetchJson<Precipitations[]>(
    `${apiURL}station/${sector}/monthly-precip?${qParams.toString()}`,
    config
  );
};

export const generalTable = (from: string, to: string, token: string) => {
  const headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Authorization", `Bearer ${token}`);

  const config: RequestInit = {
    method: "GET",
    mode: "cors",
    headers,
  };

  const qParams = new URLSearchParams();
  qParams.append("start_datetime", `${from}T00:00`);
  qParams.append("end_datetime", `${to}T00:00`);
  return mustFetchJson<Summary[]>(
    `${apiURL}station/all/summary?${qParams.toString()}`,
    config
  );
};

export const seasonsSummariesTable = (
  from: string,
  to: string,
  sector: string,
  token: string
) => {
  const headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Authorization", `Bearer ${token}`);

  const qParams = new URLSearchParams();
  qParams.append("start_datetime", `${from}T00:00`);
  qParams.append("end_datetime", `${to}T00:00`);

  const config: RequestInit = {
    method: "GET",
    mode: "cors",
    headers,
  };

  return mustFetchJson<Summary[]>(
    `${apiURL}station/${sector}/summary?${qParams.toString()}`,
    config
  );
};

export const sectors = (token: string) => {
  const headers = new Headers();
  headers.append("Access-Control-Allow-Origin", "*");
  headers.append("Authorization", `Bearer ${token}`);

  const config: RequestInit = {
    method: "GET",
    mode: "cors",
    headers,
  };
  return mustFetchJson<Station[]>(`${apiURL}fincas`, config);
};
