import { apiURL } from "../config";

export function login(username: string, password: string) {
  const headers = new Headers();
  headers.append("Content-Type", "application/x-www-form-urlencoded");
  headers.append("Access-Control-Allow-Origin", "*");

  const urlencoded = new URLSearchParams();
  urlencoded.append("username", username);
  urlencoded.append("password", password);

  const requestOptions: RequestInit = {
    method: "POST",
    headers: headers,
    body: urlencoded,
    mode: "cors",
    redirect: "follow",
  };
  return fetch(`${apiURL}login`, requestOptions);
}
