import React, { useState, useEffect, FC, useContext } from "react";
import { generalTable } from "../../../api/tables";
import { UserStateContext } from "../../../context/auth/AuthProvider";
import { StateContext } from "../../../context/dashboard/Provider";
import { Summary } from "../../../types/summary";
import { TableHeader as Header, TdGroup } from "../shared";
import * as classes from "../tables.module.css";
import {
  redScale,
  orangeScale,
  blueScale,
  greenScale,
  yellowScale,
  grayScale,
} from "../../../utils";

const GeneralPerSector: FC = () => {
  const [data, setData] = useState<Summary[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userToken } = useContext(UserStateContext);
  const { from, to, sector } = useContext(StateContext);

  useEffect(() => {
    if (!userToken) return;
    setIsLoading(true);
    // generalTable(from, to, userToken).then(setData);
    generalTable(from, to, userToken).then((res) => {
      setData(res);
      setIsLoading(false);
    });
  }, [from, to, userToken]);

  // Formato de los summaries(filas)
  const rows = data?.map((x) => (
    <tr key={x.station.station_code}>
      <th
        className={classes.cell}
        style={{
          color: x.station.station_code === sector ? "#0d6efd" : undefined,
        }}
      >
        {x.station.title}
      </th>
      <TdGroup>
        <td
          style={{ backgroundColor: blueScale([0, 50])(x.lt10).css() as any }}
        >
          {x.lt10 ?? "-"}%
        </td>
        <td
          style={{ backgroundColor: orangeScale([0, 50])(x.gt33).css() as any }}
        >
          {x.gt30 ?? "-"}%
        </td>
        <td style={{ backgroundColor: redScale([0, 50])(x.gt33).css() as any }}>
          {x.gt33 ?? "-"}%
        </td>
      </TdGroup>

      <td
        className={classes.cell}
        style={{
          backgroundColor: greenScale([0, 3000])(x.grados_acumulados).css(),
        }}
      >
        {x.grados_acumulados ?? "-"}
      </td>

      <td className={classes.cell}>{x.grados_acumulados_promedio ?? "-"}</td>

      <td
        className={classes.cell}
        style={{
          backgroundColor: yellowScale([0, 50])(x.amplitud_termica).css(),
        }}
      >
        {x.amplitud_termica ?? "-"}
      </td>
      <td
        className={classes.cell}
        style={{
          backgroundColor: grayScale([0, 300])(x.precip_acumulada).css(),
        }}
      >
        {x.precip_acumulada ?? "-"}
      </td>
      <td className={classes.cell}>
        {x.data_percentage ? `${x.data_percentage}%` : "-"}
      </td>
    </tr>
  ));

  // Tabla
  return (
    <>
      <table className={`${classes.table}`}>
        <Header averageAccumulated mainColumnHeader="Finca" />
        {/* Mostrar las filas si hay data */}
        <tbody>{data && !isLoading && rows}</tbody>
      </table>
      {/* Si no hay data, mostrar una spinner abajo de la tabla */}
      {isLoading && (
        <div className="d-flex py-3 justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {data && data.length === 0 && (
        <div className="d-flex py-3 justify-content-center">
          <div role="status">
            <p>Sin datos</p>
          </div>
        </div>
      )}
    </>
  );
};
export default GeneralPerSector;
