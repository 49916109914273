import React, { ClassicComponent, ErrorInfo } from "react";
import Modal from "../portals/modal";

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  state = {
    currentError: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      currentError: error,
      errorInfo: info,
    });
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.state.currentError && (
          <Modal
            key="Error"
            title={"Error"}
            staticBackdrop
            accept={() =>
              this.setState({ currentError: null, errorInfo: null })
            }
          >
            <p style={{ color: "red" }}>{this.state.errorInfo}</p>
          </Modal>
        )}
      </>
    );
  }
}

export default ErrorBoundary;
